<template>
	<v-card flat class="mt-2 pa-2" color="secondary">
		<v-list color="secondary">
			<v-list-item v-for="id in partGoals" :key="id">
				<v-list-item-action>
					<v-checkbox multiple :value="id" v-model="selected"></v-checkbox>
				</v-list-item-action>
				<v-list-item-content>
					<v-list-item-title>
						{{goals[id].name}}
					</v-list-item-title>
				</v-list-item-content>
				<v-list-item-icon>
					<v-btn color="grey" small icon @click="up(id)"><v-icon>mdi-arrow-up-circle</v-icon></v-btn>
					<v-btn color="grey" small icon @click="down(id)" class="ml-0"><v-icon>mdi-arrow-down-circle</v-icon></v-btn>
				</v-list-item-icon>
			</v-list-item>
		</v-list>
		<v-card-actions>
			<v-btn color="igblack" dark v-if="selected" @click="deleteSelected">{{mwtr.DeleteButton}}</v-btn>
			<v-spacer></v-spacer>
			<AddAGoal @add="addGoals"></AddAGoal>
		</v-card-actions>
	</v-card>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	import Vuex from "vuex";
	import AddAGoal from "@/components/admin/panels/AddAGoal"
	export default {
		name: "SectionPartGoals", 
		props: {
			id: {},
			part: {}
		},
		data: () => {
			return {
				selected: [],
			}
		},
		components: {
			AddAGoal
		},
		computed: {
			...Vuex.mapState({
				goals: state => state.goals
			}),
			items(){
				var current = this.part.goals;
				return this.mwutilities.itemsArray(this.goals).filter( g => current.indexOf(g.value) < 0 )
			}, 
			partGoals(){
				const self = this;
				return this.part.goals.filter( a => self.goals[a] );
			}
		},
		watch: {
			part: {
				immediate: true, 
				handler(){
					if( !this.part.goals ){
						this.part.goals = [];
					}						
				}
			}
		},
		methods: {
			up(){

			},
			down(){

			},
			deleteSelected(){
				var selected = this.selected;
				this.part.goals = this.part.goals.filter( goal => selected.indexOf(goal) < 0 );
				this.selected = true;
			},
			addGoals(goals){
				var self = this;
				goals.forEach( goal => {
					if( self.part.goals.indexOf( goal ) < 0 ){
						self.$emit("addGoal", goal )
// self.part.goals.push( goal );
					}
				})
			}
		},

	}
// </script>"