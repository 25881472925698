<template>
	<v-dialog v-model="dialog" :width="mwstylesettings.MediumDialogWidth">
		<template v-slot:activator="{on}">
			<v-btn color="primary" v-on="on">{{mwtr.AddMoreGoals}}</v-btn>
		</template>
		<v-card>
			<v-card-title>{{mwtr.AddGoals}}</v-card-title>
			<v-card-text>
				<SearchByTags @search="(data) => search = data" :searchItems="goals" @change="(data) => filtered = data"></SearchByTags>
				<v-data-table :show-select="true" item-key="value" :search="search" v-model="selected" :headers="headers" :items="items">
				</v-data-table>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="primary" @click="addGoals">{{mwtr.AddSelectedGoals}}</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	import Vuex from "vuex";
	import SearchByTags from "@/components/admin/panels/SearchByTags"
	export default {
		name: "AddAGoal", 
		props: {
			
		},
		data: () => {
			return {
				selected: [],
				filtered: false,
				search: "",
				dialog: false,
				headers: [
					{value: "text", text: "Name"}
				],
			}
		},
		components: {
			SearchByTags
		},
		computed: {
			...Vuex.mapState({
				goals: state => state.goals
			}),
			items() {
				var self = this;

				var items = this.mwutilities.itemsArray( self.goals );
				if( self.filtered ){
					items = items.filter( goal => self.filtered.indexOf( goal.value ) > -1 )
				}
				return items;
			}
		},
		methods: {
			addGoals(){
				this.$emit("add", this.selected.map( g => g.value)  );
				this.selected = [];
				this.filtered = false;
				this.dialog = false;
			}
		}
		// created(){
			
		// }

	}
// </script>"