<template>
	<v-card flat v-if="part" width="100%">
		<v-card-text>
			<v-select v-model="part.goalGroup" :label="mwtr.GoalGroupNameLabel" :items="goalGroupItems"
				append-icon="mdi-pencil"
				@click:append="dialog = true"
			></v-select>
			<v-dialog v-model="dialog" :width="mwstylesettings.MediumDialogWidth">
				<v-card>
					<v-card-title></v-card-title>
					<v-card-text>
						<EditGoalGroup :id="part.goalGroup" @saved="modal = false"></EditGoalGroup>
					</v-card-text>
				</v-card>
			</v-dialog>
			
			<SectionPartGoals :part="part" :id="id" @addGoal="addGoal"></SectionPartGoals>
		</v-card-text>
		<v-card-actions>
			<v-spacer></v-spacer>
			<Saved :trigger="saved" @close="saved = false"></Saved>
			<v-btn @click="save" color="primary">{{mwtr.SaveButton}}</v-btn>
		</v-card-actions>
	</v-card>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	import EditGoalGroup from "@/components/admin/edits/EditGoalGroup.vue"
	import Saved from "@/components/notices/Saved"
	import SectionPartGoals from "@/components/admin/panels/SectionPartGoals.vue"
	import Vuex from "vuex";
	export default {
		name: "EditSectionPart", 
		props: {
			id: {}, 
			section: {}
		},
		data: () => {
			return {
				saved: false,
				dialog: false
			}
		},
		computed: {
			...Vuex.mapState( {
				goalGroups: state => state.goalGroups,
				tags: state => state.tags,
				sectionObj(state){
					var id = this.section
					return state.sections[id] || {}
				}, 
			}),
			goalGroupItems(){
				return this.mwutilities.itemsArray(this.goalGroups)
			},
			part(){
				return this.sectionObj.parts[this.id] || {}
			},
			goalGroup(){
				if( !this.goalGroups ){
					return {}
				}
				return this.goalGroups[this.part.goalGroup] || {}
			}
		},
		components: {
			SectionPartGoals,
			EditGoalGroup,
			Saved
		},
		methods: {
			save(){
				var part = {
					goalGroup: this.part.goalGroup, 
					goals: this.part.goals
				}
				this.$store.dispatch("updateDoc", {
					doc: "sections/" + this.section + "/parts/" + this.id,
					data: part
				}).then( ( ) => {
					this.saved = true;
				})
			},
			addGoal(goal){
				this.$store.commit("sections/ADD_GOAL", {goal: goal, part: this.id, section: this.section} )

			}
		}
	}
// </script>"